<template>
  <div class="loginForm"></div>
</template>

<script>
import { getToken } from '@/utils/login'
export default {
  name: 'loginForm',
  props: {},
  data() {
    return {}
  },
  created() {
    if (window.location.href.indexOf('?code=') !== -1) {
      console.log('登录成功！取得code授权码')
      getToken()
    } else {
      const fromUrl = localStorage.getItem('fromUrl')
      if (!fromUrl) {
        window.open(
          `http://127.0.0.1:8081/layout/resource/resourceUse`,
          '_self'
        )
      } else {
        window.open(fromUrl, '_self')
      }
    }
  },
  methods: {},
}
</script>
